import React, { useState, useEffect } from 'react'

import CustomModal from '../../helpers/CustomModal'
import welcome from '../../assets/images/tour-guide/welcome.png'
import hand from '../../assets/images/tour-guide/hand.png'
import clap from '../../assets/images/tour-guide/clap.png'
import finish from '../../assets/images/tour-guide/finish.png'
import { connect, mapStateToProps, mapDispatchToProps, cookies, checkIsCompleted } from '../../helpers/Common'
import TaskList from './TaskList'
import { LangBasicDataComplete, LangEnjoyKerjoo, LangFinishTheSteps2, LangFinishTheSteps3, LangFinishTheSteps4, LangScheduleDemo, LangTasklistTitleTR, LangWelcomeToAdminPanel } from '../../constant/languange'

const TourGuideIndicator = ({ redux, setState }) => {
  // state
  const [page, setPage] = useState(cookies.get(`tourPage-${redux.profile.id}`))
  const [progress, setProgress] = useState(0)
  const [isCompleted, setIsCompleted] = useState(false)

  // effect
  useEffect(() => {
    setState({ isUpdateStats: redux.isUpdateStats + 1 })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (redux.stats) {
      if (checkIsCompleted(redux.stats)) {
        setIsCompleted(true)
      }
    }
  }, [redux.stats])

  useEffect(() => {
    if (redux.stats) {
      const statValues = Object.values(redux.stats)

      // progress bar
      setProgress(statValues.filter(value => value > 0).length)
    }
  }, [redux.stats])

  useEffect(() => {
    if (!page) {
      if(redux.isTemporary){
        setPage('task')
        cookies.set(`tourPage-${redux.profile.id}`, 'task', { maxAge: (86400 * 365 * 5) })
      } else {
        setPage('welcome')
        cookies.set(`tourPage-${redux.profile.id}`, 'welcome', { maxAge: (86400 * 365 * 5) })
      }
    }

    // setTimeout(() => {
    if (isCompleted) {
      setPage('finish')
      cookies.set(`tourPage-${redux.profile.id}`, 'finish', { maxAge: (86400 * 365 * 5) })
    }

    if (page === 'finish' && !isCompleted) {
      setPage('task')
      cookies.set(`tourPage-${redux.profile.id}`, 'task', { maxAge: (86400 * 365 * 5) })
      cookies.set(`isStopTour-${redux.profile.id}`, 0, { maxAge: (86400 * 365 * 5) })
      setState({ isStopTour: false })
    }

    if (page === 'close-tour' && !isCompleted) {
      setPage('task')
      cookies.set(`tourPage-${redux.profile.id}`, 'task', { maxAge: (86400 * 365 * 5) })
      cookies.set(`isStopTour-${redux.profile.id}`, 0, { maxAge: (86400 * 365 * 5) })
      setState({ isStopTour: false })
    }
    // }, 2000)
    // eslint-disable-next-line
  }, [redux.stats, page, redux.profile.id])

  // handler
  const close = (e) => {
    cookies.set(`notFirstLogin-${redux.profile.id}`, 1, { maxAge: (86400 * 365 * 5) })
    setState({ notFirstLogin: 1 })
    document.body.style.overflow = 'auto'
    setOpenIndicator(false)
  }

  // function
  const setOpenIndicator = (value) => {
    setState({ openIndicator: value })
  }

  const stopTour = () => {
    cookies.set(`isStopTour-${redux.profile.id}`, 1, { maxAge: (86400 * 365 * 5) })
    setState({ isStopTour: true })
    cookies.set(`tourPage-${redux.profile.id}`, 'close-tour', { maxAge: (86400 * 365 * 5) })
    cookies.set(`clickFinish-${redux.profile.id}`, 1, { maxAge: (86400 * 365 * 5) })
    cookies.remove(`showAlertProgress-${redux.profile.id}`)
    window.location.reload()
  }

  const handleScheduleDemo = () => {
    window.open(
      'https://kerjoo.com/demo/',
      '_blank'
    )
    setPage('task')
    cookies.set(`tourPage-${redux.profile.id}`, 'task', { maxAge: (86400 * 365 * 5) })
    cookies.set(`notFirstLogin-${redux.profile.id}`, 1, { maxAge: (86400 * 365 * 5) })
    setState({ notFirstLogin: 1 })
  }

  const content = (
    <div className={`${redux.darkModeOn ? 'bg-dark' : 'bg-white'} px-3 py-5 px-md-5 shadow position-relative mx-auto`} style={{ borderRadius: '20px', maxWidth: '572px', boxSizing: 'border-box !important', zIndex: '10000' }}>

      <button onClick={close} className='btn p-0 position-absolute' style={{ top: '28px', right: '28px' }}>
        <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M25.6456 10.3665C25.5333 10.254 25.4 10.1648 25.2532 10.1039C25.1064 10.043 24.949 10.0116 24.79 10.0116C24.6311 10.0116 24.4737 10.043 24.3269 10.1039C24.1801 10.1648 24.0467 10.254 23.9344 10.3665L18 16.2888L12.0656 10.3544C11.9532 10.242 11.8198 10.1529 11.673 10.0921C11.5262 10.0313 11.3689 10 11.21 10C11.0511 10 10.8937 10.0313 10.7469 10.0921C10.6001 10.1529 10.4667 10.242 10.3544 10.3544C10.242 10.4667 10.1529 10.6001 10.0921 10.7469C10.0313 10.8937 10 11.0511 10 11.21C10 11.3689 10.0313 11.5262 10.0921 11.673C10.1529 11.8198 10.242 11.9532 10.3544 12.0656L16.2888 18L10.3544 23.9344C10.242 24.0468 10.1529 24.1802 10.0921 24.327C10.0313 24.4738 10 24.6311 10 24.79C10 24.9489 10.0313 25.1063 10.0921 25.2531C10.1529 25.3999 10.242 25.5333 10.3544 25.6456C10.4667 25.758 10.6001 25.8471 10.7469 25.9079C10.8937 25.9687 11.0511 26 11.21 26C11.3689 26 11.5262 25.9687 11.673 25.9079C11.8198 25.8471 11.9532 25.758 12.0656 25.6456L18 19.7112L23.9344 25.6456C24.0468 25.758 24.1802 25.8471 24.327 25.9079C24.4738 25.9687 24.6311 26 24.79 26C24.9489 26 25.1063 25.9687 25.2531 25.9079C25.3999 25.8471 25.5333 25.758 25.6456 25.6456C25.758 25.5333 25.8471 25.3999 25.9079 25.2531C25.9687 25.1063 26 24.9489 26 24.79C26 24.6311 25.9687 24.4738 25.9079 24.327C25.8471 24.1802 25.758 24.0468 25.6456 23.9344L19.7112 18L25.6456 12.0656C26.1068 11.6044 26.1068 10.8277 25.6456 10.3665Z' fill={redux.darkModeOn ? 'white' : 'black'} />
        </svg>
      </button>

      {page === 'welcome' && (
        <div className='d-flex flex-column align-items-center'>
          <img style={{ maxWidth: '368px', marginBottom: '40px' }} src={welcome} alt='welcome!' />
          <div className='d-flex align-items-center' style={{ marginBottom: '20px' }}>
            <p className='mb-0 mr-1 text-center' style={{ fontSize: '24px', fontWeight: '700', lineHeight: '34px' }}>{LangWelcomeToAdminPanel} <img src={hand} width={24} alt='hand' /></p>
          </div>
          <p className='text-center' style={{ fontSize: '16px', maxWidth: '460px', lineHeight: '22.4px' }}>{LangFinishTheSteps2}</p>
          <p className='text-center' style={{ fontSize: '16px', maxWidth: '460px', lineHeight: '22.4px', marginBottom: '32px' }}>{LangFinishTheSteps4}</p>
          <div className='d-flex' style={{ gap: '.5rem' }}>
            <button onClick={() => { setPage('task'); cookies.set(`tourPage-${redux.profile.id}`, 'task', { maxAge: (86400 * 365 * 5) }); cookies.set(`notFirstLogin-${redux.profile.id}`, 1, { maxAge: (86400 * 365 * 5) }); setState({ notFirstLogin: 1 }) }} className='btn text-white rounded-pill' style={{ background: '#25A091', padding: '12px 40px', fontSize: '16px' }}>Let’s Go!</button>
            <button className='btn btn-outline-primary rounded-pill' style={{ padding: '12px 40px', fontSize: '16px' }} onClick={handleScheduleDemo}>{LangScheduleDemo}</button>
          </div>
        </div>
      )}

      {page === 'task' && (
        <div className='d-flex flex-column align-items-center'>
          <div className='d-flex align-items-center mt-3 mt-md-0' style={{ marginBottom: '20px' }}>
            <p className='mb-0 mr-1 text-center' style={{ fontSize: '24px', fontWeight: '700', lineHeight: '34px' }}>{LangTasklistTitleTR}</p>
          </div>

          {/* progress bar */}
          <div style={{ marginBottom: '28px' }} className='d-flex align-items-center w-100 justify-content-center'>
            <p className='mr-2 mb-0' style={{ fontSize: '16px' }}>{Math.ceil(progress / 6 * 100)}%</p>
            <div className='rounded-pill' style={{ background: '#ECECEC', width: '80%', height: '30px', overflow: 'hidden' }}>
              <div className='rounded-pill' style={{ background: '#25A091', width: `${Math.ceil(progress / 6 * 100)}%`, height: '30px', transition: 'width 500ms ease-out' }} />
            </div>
          </div>

          <p className='text-center' style={{ fontSize: '16px', maxWidth: '380px', lineHeight: '22.4px', marginBottom: '48px' }}>{LangFinishTheSteps3}</p>

          {/* task list */}
          <TaskList stats={redux.stats} />
        </div>
      )}

      {page === 'finish' && (
        <div className='d-flex flex-column align-items-center'>
          <p className='mb-0 mr-1' style={{ fontSize: '24px', fontWeight: '700', lineHeight: '34px' }}>{LangBasicDataComplete} <img src={clap} width={24} alt='hand' /></p>
          <div className='d-flex align-items-center' style={{ marginBottom: '48px' }}>
            <p className='mb-0 mr-1 text-center' style={{ fontSize: '24px', fontWeight: '700', lineHeight: '34px', color: '#25A091' }}>{LangEnjoyKerjoo}</p>
          </div>
          <img style={{ maxWidth: '420px', marginBottom: '110px' }} src={finish} alt='welcome!' />
          <button onClick={() => { close(); stopTour() }} className='btn text-white rounded-pill' style={{ background: '#25A091', padding: '12px 80px', fontSize: '16px' }}>OK</button>
        </div>
      )}
    </div>
  )

  return (
    <>
      {redux.openIndicator && !redux.profile.supervisor_groups ? <CustomModal content={content} /> : ''}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TourGuideIndicator)
